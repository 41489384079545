import dynamic from 'next/dynamic';

import type { MembershipBannerSectionProps } from 'src/components/sections/MembershipBannerSection/MembershipBannerSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { MembershipBannerSectionContent } from './types';

export const renderer: SectionRenderer<MembershipBannerSectionProps> =
  dynamic<MembershipBannerSectionProps>(() =>
    import('src/components/sections/MembershipBannerSection/MembershipBannerSection').then(
      (mod) => mod.MembershipBannerSection,
    ),
  );

export const transformer: Transformer<
  MembershipBannerSectionContent,
  MembershipBannerSectionProps
> = (content, { pageConfig }) => {
  return {
    uid: content._metadata.uid,
    props: {
      buttonHref: content.button?.href,
      buttonText: content.button?.text,
      copy: content.copy,
      heading: content.heading,
      trackingTitle: content.tracking_title,
      trackingCategory: pageConfig.trackingCategory,
    },
  };
};
