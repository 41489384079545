import React from 'react';
import dynamic from 'next/dynamic';

import type { PreviewErrorOverlayProps } from 'src/components/atoms/PreviewErrorOverlay/PreviewErrorOverlay';
import { UnsupportedSection } from 'src/components/sections/UnsupportedSection/UnsupportedSection';
import { renderers } from 'src/content/renderers';
import type { SerializedUISection } from 'src/types/Section';
import * as styles from './Sections.styles';

const PreviewErrorOverlay = dynamic<PreviewErrorOverlayProps>(() =>
  import('src/components/atoms/PreviewErrorOverlay/PreviewErrorOverlay').then(
    (mod) => mod.PreviewErrorOverlay,
  ),
);

interface SectionsProps {
  isPreview: boolean;
  sections: SerializedUISection[];
}

export const Sections = ({ isPreview, sections }: SectionsProps) => (
  <>
    {sections.map((section) => {
      const { errors = [], props, sectionName, uid } = section;
      const Component = renderers[sectionName] || UnsupportedSection;
      const showComponent = isPreview || !errors.some((error) => error.preventRender);
      const showPreviewErrors = isPreview && errors.length > 0;

      return (
        <section css={styles.section} data-section-name={sectionName} key={uid}>
          {showComponent && <Component {...props} />}
          {showPreviewErrors && <PreviewErrorOverlay errors={errors} title={sectionName} />}
        </section>
      );
    })}
  </>
);
