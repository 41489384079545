import dynamic from 'next/dynamic';

import type { ProductCarouselRecommendationsSectionProps } from 'src/components/sections/ProductCarouselRecommendationsSection/ProductCarouselRecommendationsSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { ProductCarouselRecommendationsContentSection } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<ProductCarouselRecommendationsSectionProps> =
  dynamic<ProductCarouselRecommendationsSectionProps>(() =>
    import(
      'src/components/sections/ProductCarouselRecommendationsSection/ProductCarouselRecommendationsSection'
    ).then((mod) => mod.ProductCarouselRecommendationsSection),
  );

export const transformer: Transformer<
  ProductCarouselRecommendationsContentSection,
  ProductCarouselRecommendationsSectionProps
> = ({ _metadata, placement_type, tracking_title }, { pageMetadata, positionMetadata }) => ({
  uid: _metadata.uid,
  props: {
    currency: pageMetadata.currency,
    hasDisabledCookies: !pageMetadata.functionalCookiesConsented,
    placementType: placement_type,
    sessionId: pageMetadata.recommendationsSessionId,
    trackingTitle: tracking_title,
    lazyImages: lazyLoadImages(positionMetadata.position),
  },
});
