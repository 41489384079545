import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

export const container = css`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  border-bottom-left-radius: ${pxToRem(16)};
  padding: ${pxToRem(16)};
  font-size: 0;
  background-color: var(--neutral-black-10);
  box-shadow: 0 0 5px var(--neutral-black-70);
`;
