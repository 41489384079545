import dynamic from 'next/dynamic';

import type { ProductCarouselSectionProps } from 'src/components/sections/ProductCarouselSection/ProductCarouselSection';
import { fetchProducts } from 'src/services/catalog/fetchProducts';
import type { SectionRenderer } from 'src/types/Section';
import type { AsyncTransformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { ProductCarouselContentSection } from './types';
import { getProductsValidationErrors } from './validation';

/* istanbul ignore next */
export const renderer: SectionRenderer<ProductCarouselSectionProps> =
  dynamic<ProductCarouselSectionProps>(() =>
    import('src/components/sections/ProductCarouselSection/ProductCarouselSection').then(
      (mod) => mod.ProductCarouselSection,
    ),
  );

const getProductCodes = (products: { product_code: string }[]) =>
  products.map(({ product_code }) => Number(product_code));

export const transformer: AsyncTransformer<
  ProductCarouselContentSection,
  ProductCarouselSectionProps
> = async (
  { _metadata, heading, products, tracking_title },
  { pageConfig, pageMetadata, positionMetadata },
) => {
  const catalogServiceProducts = await fetchProducts(getProductCodes(products));
  /* istanbul ignore next */
  const renderableProducts = (catalogServiceProducts || []).filter(
    (product) => product.purchasable,
  );
  const errors = getProductsValidationErrors(
    getProductCodes(products),
    catalogServiceProducts,
    renderableProducts,
  );

  return {
    uid: _metadata.uid,
    props: {
      title: heading,
      products: renderableProducts,
      currency: pageMetadata.currency,
      pageType: pageConfig.pageType,
      trackingTitle: tracking_title,
      lazyImages: lazyLoadImages(positionMetadata.position),
    },
    errors,
  };
};
