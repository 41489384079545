import React from 'react';
import { SolidButtonLink } from '@noths/polaris-client-ribbons-design-system';

import { PUBLIC_API_BASE_PATH } from 'src/constants/environment';
import * as styles from './PreviewHeader.styles';

export const PreviewHeader = () => (
  <header css={styles.container}>
    <SolidButtonLink compact href={`${PUBLIC_API_BASE_PATH}clearPreview`}>
      Exit Preview Mode
    </SolidButtonLink>
  </header>
);
