import dynamic from 'next/dynamic';

import type { LinksBlockSectionProps } from 'src/components/sections/LinksBlockSection/LinksBlockSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { LinksBlockSectionContent } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<LinksBlockSectionProps> = dynamic<LinksBlockSectionProps>(
  () =>
    import('src/components/sections/LinksBlockSection/LinksBlockSection').then(
      (mod) => mod.LinksBlockSection,
    ),
);

export const transformer: Transformer<LinksBlockSectionContent, LinksBlockSectionProps> = ({
  _metadata,
  background_colour,
  heading,
  image,
  link_groups,
  sub_heading,
  tracking_title,
}) => {
  return {
    uid: _metadata.uid,
    props: {
      title: heading,
      subHeading: sub_heading,
      imageUrl: image.image_source.url,
      imageAlt: image.alt_text,
      backgroundColor: background_colour,
      linkGroups: link_groups.map(({ links, open, title }) => {
        return {
          title,
          open,
          links: links.map(({ href, title }) => {
            return {
              text: title,
              href,
            };
          }),
        };
      }),
      trackingTitle: tracking_title,
    },
  };
};
