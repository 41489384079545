import dynamic from 'next/dynamic';

import type { CopyBlockSectionProps } from 'src/components/sections/CopyBlockSection/CopyBlockSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { CopyBlockContentSection } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<CopyBlockSectionProps> = dynamic<CopyBlockSectionProps>(() =>
  import('src/components/sections/CopyBlockSection/CopyBlockSection').then(
    (mod) => mod.CopyBlockSection,
  ),
);

export const transformer: Transformer<CopyBlockContentSection, CopyBlockSectionProps> = ({
  _metadata,
  alignment,
  copy,
  tracking_title,
}) => ({
  uid: _metadata.uid,
  props: {
    children: copy,
    alignment,
    trackingTitle: tracking_title,
  },
});
