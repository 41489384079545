import dynamic from 'next/dynamic';

import type { SectionTitleSectionProps } from 'src/components/sections/SectionTitleSection/SectionTitleSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { SectionTitleContentSection } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<SectionTitleSectionProps> =
  dynamic<SectionTitleSectionProps>(() =>
    import('src/components/sections/SectionTitleSection/SectionTitleSection').then(
      (mod) => mod.SectionTitleSection,
    ),
  );

export const transformer: Transformer<SectionTitleContentSection, SectionTitleSectionProps> = (
  { _metadata, title },
  { pageConfig, positionMetadata },
) => ({
  uid: _metadata.uid,
  props: {
    title,
    headingIsPageTitle: positionMetadata.position === 0 && pageConfig.pageType !== 'homepage',
  },
});
