import { fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import getConfig from 'next/config';

import { logger } from 'src/utils/serverLogger';
import type { CatalogServiceProductData } from './types';

const config = getConfig();
const { CATALOG_API_ENDPOINT, CATALOG_API_PATH } = config.publicRuntimeConfig;
const url = `${CATALOG_API_ENDPOINT}${CATALOG_API_PATH}`;
const WARNING_MESSAGE = 'failed to get batched product details data';

export const fetchProducts = async (
  productCodes: Array<number>,
): Promise<CatalogServiceProductData[] | null> => {
  try {
    const response = await fetchJSONWithTimeout(url, {
      method: 'POST',
      body: {
        productCodes,
      },
      timeout: 1000,
    });
    if (!response.ok) {
      logger.warn(WARNING_MESSAGE, {
        error: `bad response code from cat-service-api: ${String(response.status)}`,
      });

      return null;
    }

    const body = await response.json();

    // @ts-ignore
    return body.products;
  } catch (error) {
    logger.warn(WARNING_MESSAGE, { error: `${String(error)}` });
  }

  return null;
};
