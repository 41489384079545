import dynamic from 'next/dynamic';

import type {
  ButtonSectionProps,
  ButtonTextColorMode,
} from 'src/components/sections/ButtonSection/ButtonSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { ButtonSectionContent } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<ButtonSectionProps> = dynamic<ButtonSectionProps>(() =>
  import('src/components/sections/ButtonSection/ButtonSection').then((mod) => mod.ButtonSection),
);

export const transformer: Transformer<ButtonSectionContent, ButtonSectionProps> = ({
  _metadata,
  background_color,
  href,
  text,
  text_color_mode,
  tracking_title,
}) => ({
  uid: _metadata.uid,
  props: {
    customBackgroundColor: background_color,
    textColorMode: text_color_mode as ButtonTextColorMode,
    href,
    children: text,
    trackingTitle: tracking_title,
  },
});
