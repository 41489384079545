import dynamic from 'next/dynamic';

import type { PartnerBlockSectionProps } from 'src/components/sections/PartnerBlockSection/PartnerBlockSection';
import { fetchProducts } from 'src/services/catalog/fetchProducts';
import type { SectionRenderer } from 'src/types/Section';
import type { AsyncTransformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import { contentPartnerToModel } from './contentToModel';
import type { ContentPartner, PartnerBlockContentSection } from './types';
import { getProductsValidationErrors } from './validation';

/* istanbul ignore next */
export const renderer: SectionRenderer<PartnerBlockSectionProps> =
  dynamic<PartnerBlockSectionProps>(() =>
    import('src/components/sections/PartnerBlockSection/PartnerBlockSection').then(
      (mod) => mod.PartnerBlockSection,
    ),
  );

const getAllProductCodesFromPartners = (partners: ContentPartner[]) =>
  partners
    .reduce((prev, curr) => prev.concat(curr.products as never), [])
    .map(({ product_code }) => Number(product_code));

export const transformer: AsyncTransformer<
  PartnerBlockContentSection,
  PartnerBlockSectionProps
> = async (
  { _metadata, copy, heading, partners, tracking_title },
  { pageConfig, pageMetadata, positionMetadata },
) => {
  const productCodes = getAllProductCodesFromPartners(partners);
  const catalogServiceProducts = await fetchProducts(productCodes);
  const renderableProducts = (catalogServiceProducts || [])?.filter(
    (product) => product.purchasable,
  );
  const errors = getProductsValidationErrors(
    productCodes,
    catalogServiceProducts,
    renderableProducts,
  );

  return {
    uid: _metadata.uid,
    props: {
      heading,
      copy,
      partners: partners.map((partner) => contentPartnerToModel(partner, renderableProducts)),
      trackingTitle: tracking_title,
      currency: pageMetadata.currency,
      pageType: pageConfig.pageType,
      lazyImages: lazyLoadImages(positionMetadata.position),
    },
    errors,
  };
};
