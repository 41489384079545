import { getContentstackImagesForWidths } from '@noths/polaris-client-utils';
import dynamic from 'next/dynamic';

import type { StandardBannerSectionProps } from 'src/components/sections/StandardBannerSection/StandardBannerSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { StandardBannerContentSection } from './types';
import { validateStandardBanner } from './validation';

/* istanbul ignore next */
export const renderer: SectionRenderer<StandardBannerSectionProps> =
  dynamic<StandardBannerSectionProps>(() =>
    import('src/components/sections/StandardBannerSection/StandardBannerSection').then(
      (mod) => mod.StandardBannerSection,
    ),
  );

export const transformer: Transformer<StandardBannerContentSection, StandardBannerSectionProps> = (
  content,
  { positionMetadata },
) => {
  const { _metadata, background_color, copy, cta, heading, image, tracking_title } = content;
  const images = image.image_source
    ? getContentstackImagesForWidths(image.image_source.url, [360, 500, 700, 900, 1132], {
        aspectRatio: '16:9',
      })
    : [];

  return {
    uid: _metadata.uid,
    props: {
      heading: heading,
      backgroundColor: background_color,
      images,
      imageWidth: 360,
      imageHeight: 216,
      imageAlt: image.alt_text,
      copy: copy || '',
      linkHref: cta?.href || '',
      linkText: cta?.title || '',
      trackingTitle: tracking_title,
      lazyImages: lazyLoadImages(positionMetadata.position),
    },
    errors: validateStandardBanner(content),
  };
};
