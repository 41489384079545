import { getContentstackImagesForWidths } from '@noths/polaris-client-utils';
import dynamic from 'next/dynamic';

import type { HeroBannerSectionProps } from 'src/components/sections/HeroBannerSection/HeroBannerSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { HeroBannerSectionContent } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<HeroBannerSectionProps> = dynamic<HeroBannerSectionProps>(
  () =>
    import('src/components/sections/HeroBannerSection/HeroBannerSection').then(
      (mod) => mod.HeroBannerSection,
    ),
);

const getPromoPropertyName = (propertyName: string, featureFlagValue: string | undefined) => {
  switch (featureFlagValue) {
    case 'promo1':
      return `${propertyName}_promo1`;
    case 'promo2':
      return `${propertyName}_promo2`;
    default:
      return propertyName;
  }
};

/* istanbul ignore next */
export const transformer: Transformer<HeroBannerSectionContent, HeroBannerSectionProps> = (
  content,
  { pageConfig, pageMetadata, positionMetadata },
) => {
  const siteWidePromoFeatureFlag = pageMetadata.featureFlags?.['site_wide_promo'];

  return {
    uid: content._metadata.uid,
    props: {
      backgroundColor: content.background_color,
      headingIsPageTitle: positionMetadata.position === 0 && pageConfig.pageType !== 'homepage',
      images: getContentstackImagesForWidths(
        content.image.image_source.url,
        [360, 500, 700, 900, 1132],
        {
          aspectRatio: '16:9',
        },
      ),
      imageWidth: 360,
      imageHeight: 216,
      imageAlt: content.image.alt_text,
      textColorMode: content.text_color_mode,
      headingLine1: content.heading[getPromoPropertyName('line1', siteWidePromoFeatureFlag)],
      headingLine2: content.heading[getPromoPropertyName('line2', siteWidePromoFeatureFlag)],
      ...(content.heading.underline.show_underline && {
        headingUnderlineColor: content.heading.underline.underline_color,
      }),
      subHeading: content[getPromoPropertyName('sub_heading', siteWidePromoFeatureFlag)] || '',
      copy: content[getPromoPropertyName('copy', siteWidePromoFeatureFlag)] || '',
      buttonBackgroundColor: content.button?.background_color || '',
      linkHref: content.button?.[getPromoPropertyName('url', siteWidePromoFeatureFlag)] || '',
      buttonText: content.button?.[getPromoPropertyName('text', siteWidePromoFeatureFlag)] || '',
      buttonTextColorMode: content.button?.text_color || 'light',
      trackingTitle: content[getPromoPropertyName('tracking_title', siteWidePromoFeatureFlag)],
      lazyImages: lazyLoadImages(positionMetadata.position),
    },
  };
};
