import { getContentstackImagesForWidths } from '@noths/polaris-client-utils';
import dynamic from 'next/dynamic';

import type { YouTubeVideoBlockBlockSection } from 'src/components/sections/YouTubeVideoBlockSection/YouTubeVideoBlockSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { VideoBlockContentSection } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<YouTubeVideoBlockBlockSection> =
  dynamic<YouTubeVideoBlockBlockSection>(() =>
    import('src/components/sections/YouTubeVideoBlockSection/YouTubeVideoBlockSection').then(
      (mod) => mod.YouTubeVideoBlockSection,
    ),
  );

export const transformer: Transformer<VideoBlockContentSection, YouTubeVideoBlockBlockSection> = (
  { _metadata, caption = '', thumbnail, title = '', tracking_title, youtube_id },
  { positionMetadata },
) => ({
  uid: _metadata.uid,
  props: {
    youtubeId: youtube_id,
    ...(thumbnail
      ? {
          thumbnailImages: getContentstackImagesForWidths(
            thumbnail.url,
            [360, 500, 700, 900, 1132],
            {
              aspectRatio: '16:9',
            },
          ),
          thumbnailWidth: 360,
          thumbnailHeight: 216,
        }
      : { thumbnailImages: [] }),
    title,
    caption,
    trackingTitle: tracking_title,
    lazyImages: lazyLoadImages(positionMetadata.position),
  },
});
