import { renderer as button } from './sections/button/button';
import { renderer as category_carousel } from './sections/categoryCarousel/categoryCarousel';
import { renderer as content_tiles } from './sections/contentTiles/contentTiles';
import { renderer as copy_block } from './sections/copyBlock/copyBlock';
import { renderer as department_hero_banner } from './sections/departmentHeroBanner/departmentHeroBanner';
import { renderer as hero_banner } from './sections/heroBanner/heroBanner';
import { renderer as links_block } from './sections/linksBlock/linksBlock';
import { renderer as membership_banner } from './sections/membershipBanner/membershipBanner';
import { renderer as partner_block } from './sections/partnerBlock/partnerBlock';
import { renderer as preview_error } from './sections/previewError/previewError';
import { renderer as product_carousel } from './sections/productCarousel/productCarousel';
import { renderer as product_carousel_recommendations } from './sections/productCarouselRecommendations/productCarouselRecommendations';
import { renderer as section_title } from './sections/sectionTitle/sectionTitle';
import { renderer as standard_banner } from './sections/standardBanner/standardBanner';
import { renderer as video_block } from './sections/videoBlock/videoBlock';

export const renderers = {
  button,
  category_carousel,
  content_tiles,
  copy_block,
  department_hero_banner,
  hero_banner,
  links_block,
  partner_block,
  preview_error,
  membership_banner,
  product_carousel,
  product_carousel_recommendations,
  section_title,
  standard_banner,
  video_block,
};

export type ContentBlockRendererType = keyof typeof renderers;
