import dynamic from 'next/dynamic';

import type { ContentTilesSectionProps } from 'src/components/sections/ContentTilesSection/ContentTilesSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { ContentTilesSectionContent } from './types';
import { validateContentTiles } from './validation';

/* istanbul ignore next */
export const renderer: SectionRenderer<ContentTilesSectionProps> =
  dynamic<ContentTilesSectionProps>(() =>
    import('src/components/sections/ContentTilesSection/ContentTilesSection').then(
      (mod) => mod.ContentTilesSection,
    ),
  );

export const transformer: Transformer<ContentTilesSectionContent, ContentTilesSectionProps> = ({
  _metadata,
  items,
  tracking_title,
}) => {
  return {
    uid: _metadata.uid,
    props: {
      tiles: items.map(({ image, tile_background_color, title, url }) => ({
        title,
        href: url,
        imageSrc: image?.url || null,
        ...(tile_background_color.custom_background_color && {
          backgroundColor: tile_background_color.background_color,
        }),
      })),
      trackingTitle: tracking_title,
    },
    errors: validateContentTiles(items),
  };
};
