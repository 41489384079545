import React from 'react';

import { HiddenPageTitle } from 'src/components/atoms/HiddenPageTitle/HiddenPageTitle';
import { PreviewHeader } from 'src/components/atoms/PreviewHeader/PreviewHeader';
import { Seo } from 'src/components/atoms/Seo/Seo';
import { Sections } from 'src/components/molecules/Sections/Sections';
import type { ContentPageProps } from 'src/types/PageProps';
import type { SerializedUISection } from 'src/types/Section';
import * as styles from './RibbonsPage.styles';

const blockHasPageTitle = (blocks: SerializedUISection[]) =>
  blocks.some((block) => block.props.headingIsPageTitle);

export const RibbonsPage = ({
  pageContent: { sections, seo, title },
  pageMetadata: { isPreview },
}: ContentPageProps) => {
  const renderHiddenPageTitle = !blockHasPageTitle(sections);

  return (
    <div css={styles.container}>
      <Seo {...seo} />
      {renderHiddenPageTitle && <HiddenPageTitle>{title}</HiddenPageTitle>}
      {isPreview && <PreviewHeader />}
      {sections ? <Sections isPreview={isPreview} sections={sections} /> : null}
    </div>
  );
};
