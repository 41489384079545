import { css } from 'styled-components';

export const hiddenOffScreen = css`
  display: block;
  position: absolute;
  width: 1px;
  height: 1px;
  top: -99999px;
  left: -99999px;
`;
