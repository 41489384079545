import React from 'react';
import Head from 'next/head';

export interface SeoProps {
  canonical?: string;
  description: string;
  keywords?: string;
  linkedData?: Record<string, unknown>;
  robots?: string;
  title: string;
}
export const Seo = ({ canonical, description, keywords, linkedData, robots, title }: SeoProps) => (
  <Head>
    <title>{title}</title>
    <meta content={description} name="description" />
    {keywords && <meta content={keywords} name="keywords" />}
    {robots && <meta content={robots} name="robots" />}
    {canonical && <link href={canonical} rel="canonical" />}
    {linkedData && (
      <script
        dangerouslySetInnerHTML={{ __html: JSON.stringify(linkedData) }}
        type="application/ld+json"
      />
    )}
  </Head>
);
