/* istanbul ignore file */
import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const container = css`
  *:not([data-section-name]) + [data-section-name='section_title'] {
    margin-top: ${pxToRem(spacing.xxl)};

    ${mediaQuery(
      { min: minBreakpoints.L },
      css`
        margin-top: ${pxToRem(56)};
      `,
    )}
  }
`;
