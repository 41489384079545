import { getContentstackImagesForWidths } from '@noths/polaris-client-utils';
import dynamic from 'next/dynamic';

import type { HeroBannerSectionProps } from 'src/components/sections/HeroBannerSection/HeroBannerSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import type { DepartmentHeroBannerSectionContent } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<HeroBannerSectionProps> = dynamic<HeroBannerSectionProps>(
  () =>
    import('src/components/sections/HeroBannerSection/HeroBannerSection').then(
      (mod) => mod.HeroBannerSection,
    ),
);

export const transformer: Transformer<
  DepartmentHeroBannerSectionContent,
  HeroBannerSectionProps
> = (
  { _metadata, background_color, heading, image, sub_heading, text_color_mode, tracking_title },
  { pageConfig, positionMetadata },
) => ({
  uid: _metadata.uid,
  props: {
    headingLine1: heading,
    headingIsPageTitle: positionMetadata.position === 0 && pageConfig.pageType !== 'homepage',
    backgroundColor: background_color,
    textColorMode: text_color_mode,
    images: getContentstackImagesForWidths(image.image_source.url, [360, 500, 700, 900, 1132], {
      aspectRatio: '16:9',
    }),
    imageWidth: 360,
    imageHeight: 216,
    imageAlt: image.alt_text,
    subHeading: sub_heading,
    lazyImages: lazyLoadImages(positionMetadata.position),
    trackingTitle: tracking_title,
  },
});
