/* istanbul ignore file */
import dynamic from 'next/dynamic';

import type { ContentstackSection } from 'src/types/Contentstack';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';

/* istanbul ignore next */
export const renderer: SectionRenderer<Record<string, never>> = dynamic(() =>
  import('src/components/sections/UnsupportedSection/UnsupportedSection').then(
    (mod) => mod.UnsupportedSection,
  ),
);

export const createPreviewErrorTransformer = (errorMessage: string) => {
  const previewErrorTransformer: Transformer<ContentstackSection, Record<string, never>> = ({
    _metadata,
  }) => ({
    uid: _metadata.uid,
    props: {},
    errors: [{ message: errorMessage }],
  });

  return previewErrorTransformer;
};
